<h5 class="alert">{{ message }}</h5>
<div class="categories-container">
  <div class="categories-section">
    <h3>Categories <a class="sub add" (click)="toggleAddingCategory()">Add Category</a></h3>
    <div>
      <div class="update" *ngIf="addingCategory">
        <h4>Add Category</h4>
        <h5>* denotes a required field. Category name is required in English, but can be specified in additional languages</h5>
        <div>
          <p class="alert">{{ errorMessage }}</p>
          <label>English</label>
          <div class="textbox">
            <label>Category Name*</label>
          </div>
          <div class="textbox">
            <input [ngClass]="{ error: error }" [(ngModel)]="newCategory.Name" />
          </div>

          <div *ngFor="let language of languages">
            <div *ngIf="language !== 'en' && translatedCategories[language]">
              <label>{{language}}:</label>
              <dd>
                <input class="checkbox" type="checkbox" [checked]="!translatedCategories[language]?.userUpdated"
                  (change)="translatedCategories[language].userUpdated = !translatedCategories[language]?.userUpdated" />
                Automatically Update
              </dd>
              <div class="textbox">
                <label>Category Name</label>
              </div>
              <div class="textbox">
                <input [ngClass]="{ error: error }" [(ngModel)]="translatedCategories[language].Name" />
              </div>
            </div>
          </div>

          <div>
            <label>Icon</label>
          </div>
          <div>
            <input [ngClass]="{ error: error }" [(ngModel)]="newCategory.Icon" />
          </div>
        </div>

        <br />
        <button (click)="add()">Add</button>
        <br />
        <a class="sub" (click)="cancel()">Cancel</a>
      </div>
      <ul class="menu">
        <li class="row" *ngFor="let item of categories">
          <a routerLink="/home/subcategories/{{ item.key }}">
            <mat-icon>{{ item.Icon }}</mat-icon>
            {{ item.Name }}
          </a>
          <button mat-icon-button class="delete" (click)="delete(item.key)">
            <mat-icon>delete</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div class="provider-categories-section">
    <h3>Provider Categories <a class="sub add" (click)="toggleAddingProviderCategory()">Add Provider Category</a></h3>
    <div>
      <div class="update" *ngIf="addingProviderCategory">
        <h4>Add Provider Category</h4>
        <h5>* denotes a required field. Provider category name is required in English, but can be specified in additional languages</h5>
        <div>
          <p class="alert">{{ providerErrorMessage }}</p>
          <label>English</label>
          <div class="textbox">
            <label>Provider Category Name*</label>
          </div>
          <div class="textbox">
            <input [ngClass]="{ error: providerError }" [(ngModel)]="newProviderCategory.Name" />
          </div>

          <div *ngFor="let language of languages">
            <div *ngIf="language !== 'en' && translatedCategories[language]">
              <label>{{language}}:</label>
              <dd>
                <input class="checkbox" type="checkbox" [checked]="!translatedCategories[language]?.userUpdated"
                  (change)="translatedCategories[language].userUpdated = !translatedCategories[language]?.userUpdated" />
                Automatically Update
              </dd>
              <div class="textbox">
                <label>Provider Category Name</label>
              </div>
              <div class="textbox">
                <input [ngClass]="{ error: error }" [(ngModel)]="translatedCategories[language].Name" />
              </div>
            </div>
          </div>

          <div>
            <label>Icon</label>
          </div>
          <div>
            <input [ngClass]="{ error: providerError }" [(ngModel)]="newProviderCategory.Icon" />
          </div>
        </div>

        <br />
        <button (click)="add(true)">Add</button>
        <br />
        <a class="sub" (click)="cancel(true)">Cancel</a>
      </div>
      <ul class="menu">
        <li class="row" *ngFor="let item of providerCategories">
          <a routerLink="/home/editProviderCategory/{{ item.key }}">
            <mat-icon>{{ item.Icon }}</mat-icon>
            {{ item.Name }}
          </a>
          <button mat-icon-button class="delete" (click)="delete(item.key, true)">
            <mat-icon>delete</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
