import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../firebase/translate.service';
import { Category, ProviderCategory } from './category';
import { Language, Program } from '../Models/program.model';
import { ProgramService } from '../Services/program.service';
import { CategoryService } from '../Services/category.service';
import { ProviderCategoryService } from 'app/Services/provider-category.service';

@Component({
  selector: 'categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
})
export class CategoriesComponent implements OnInit {
  categories: Category[];
  providerCategories: ProviderCategory[];
  newCategory: Category = new Category();
  newProviderCategory: ProviderCategory = new ProviderCategory();
  programs: Program[];
  addingCategory = false;
  addingProviderCategory = false;
  message: string;
  errorMessage: string;
  error = false;
  translations: Language[] = [];
  languages: string[];
  translatedCategories = {};

  constructor(
    private categoryAccessor: CategoryService,
    private programAccessor: ProgramService,
    private providerCategoryAccessor: ProviderCategoryService,
    private translateService: TranslateService,
  ) {
    this.categoryAccessor
      .getUpdatableCategories()
      .snapshotChanges()
      .subscribe((categories) => {
        this.categories = [];
        categories.forEach((category) => {
          let c: Category = category.payload.val();
          c.key = category.key;
          this.categories.push(c);
        });
      });
    this.providerCategoryAccessor
      .getUpdatableCategories()
      .snapshotChanges()
      .subscribe((categories) => {
        this.providerCategories = [];
        categories.forEach((category) => {
          let c: ProviderCategory = category.payload.val();
          c.key = category.key;
          this.providerCategories.push(c);
        });
      });
  }

  async ngOnInit() {
    this.programs = this.programAccessor.getCachedPrograms();
    this.languages = await this.translateService.parseLanguages();

    for (const language of this.languages) {
      this.translatedCategories[language] = {
        Name: '',
        userUpdated: false,
      };
    }
  }

  toggleAddingCategory() {
    this.addingCategory = !this.addingCategory;
    this.newCategory.Name = '';
    this.newCategory.Icon = '';
  }

  toggleAddingProviderCategory() {
    this.addingProviderCategory = !this.addingProviderCategory;
    if (this.addingProviderCategory) {
      this.newProviderCategory = new ProviderCategory();
    }
  }

  add(isProviderCategory: boolean = false) {
    let newCategory = isProviderCategory ? this.newProviderCategory : this.newCategory;
    if (!isProviderCategory) {
      (newCategory as Category).Subcategories = [];
    }

    this.translatedCategories['en'].Name = newCategory.Name;
    this.translatedCategories['en'].userUpdated = true;
    this.languages.forEach((language) => {
      this.translations[language] = {
        name: this.translatedCategories[language].Name,
        userUpdated: this.translatedCategories[language].userUpdated,
      };
    });

    if (this.translatedCategories['en'].Name.length > 5000) {
      this.message = 'Name must be less than 5000 characters';
      this.error = true;
    } else if (newCategory.Name === undefined || newCategory.Name === '') {
      this.errorMessage = 'Name is required';
      this.error = true;
    } else {
      const accessor = isProviderCategory
        ? this.providerCategoryAccessor
        : this.categoryAccessor;

      accessor
        .getUpdatableCategories()
        .push(newCategory)
        .then((snap) => {
            if (isProviderCategory) {
              this.translateService.translateProviderCategory(newCategory, snap.key, this.translations);
            } else {
              this.translateService.translateCategory(newCategory, snap.key, this.translations);
            }
          this.errorMessage = '';
          this.error = false;
          if (isProviderCategory) {
            this.addingProviderCategory = false;
          } else {
            this.addingCategory = false;
          }
        });
      this.message = 'Success';
    }
  }

  hasDependent(value: string, isProviderCategory: boolean = false): boolean {
    let result = false;
    this.programs.forEach((program) => {
      if (isProviderCategory) {
        if (program['ProviderCategoryIds'] && program['ProviderCategoryIds'].includes(value)) {
          result = true;
        }
      } else {
        if (program['CategoryId'] === value) {
          result = true;
        }
      }
    });
    return result;
  }

  delete(value: string, isProviderCategory: boolean = false) {
    const accessor = isProviderCategory ? this.providerCategoryAccessor : this.categoryAccessor;

    if (this.hasDependent(value, isProviderCategory)) {
      this.message = isProviderCategory
        ? 'Provider category cannot be deleted if in use'
        : 'Category cannot be deleted if in use';
    } else {
      if (confirm(`Are you sure you want to delete this ${isProviderCategory ? 'provider category' : 'category'}?`)) {
        this.message = 'Success';
        accessor.getUpdatableCategories().remove(value);
        this.translateService.removeCategory(value);

        if (isProviderCategory) {
          this.providerCategories = this.providerCategoryAccessor.getCachedCategories();
        } else {
          this.categories = this.categoryAccessor.getCachedCategories();
        }
      }
    }
  }

  cancel(isProviderCategory: boolean = false) {
    if (isProviderCategory) {
      this.addingProviderCategory = false;
    } else {
      this.addingCategory = false;
    }
    this.errorMessage = '';
    this.error = false;
  }
}
