import { Injectable } from '@angular/core';
import { ObjectCache } from '../Models/objectCache.model';
import { Observable, of } from 'rxjs';
import { DataService } from './data.service';
import {
  AngularFireDatabase
} from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService extends DataService<any> {
  themeElementMap = new Map();

  constructor(
    private db: AngularFireDatabase,
    private stg: AngularFireStorage,
    private ps: PreferencesService
  ) {
    super(db, `entities/${ps.getLocationCode()}/theme`, new ObjectCache<string>(), stg);
  }

  getThemeElement(elementName: string): Observable<string> {
    if (!this.themeElementMap.has(elementName)) {
      const elementObservable = super.getObjectById(elementName, '');
      elementObservable.subscribe((element) =>
        this.themeElementMap.set(elementName, of(element))
      );
      return elementObservable;
    }
    return this.themeElementMap.get(elementName);
  }

  getThemeElements(): Observable<any> {
    return super.getThemeObjects();
  }

  saveThemeElements(themeElements: Object) {
    super.getUpdatableDatabase().update(themeElements);
  }

  uploadFileToStorage(filePath: string, file: File) {
    return super.uploadFileToStorage(filePath, file);
  }

  getDownloadUrl(fileName: string): Observable<any> {
    return super.getDownloadUrl(fileName);
  }

  deleteFileFromStorage(fileUrl: string) {
    return super.deleteFileFromStorage(fileUrl);
  }

}
