import { Component, OnInit } from '@angular/core';
import { ScheduledEvent, CycleList, DayList } from './scheduledEvent';
import { ActivatedRoute, Router } from '@angular/router';
import { Timestamp } from '../programs/timestamp';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { Location } from '@angular/common';
import { ScheduleService } from '../Services/schedule.service';
import { Observable, scheduled } from 'rxjs';
import * as moment from 'moment';
import 'time-input-polyfill';

@Component({
  selector: 'edit-schedule',
  templateUrl: './editSchedule.component.html',
})
export class EditScheduleComponent implements OnInit {
  cycleList: string[] = CycleList;
  dayList: string[] = DayList;

  id: string;

  scheduleData: Observable<ScheduledEvent>;

  cycle: string;
  day: string;
  startTime: string;
  endTime: string;
  time: string;
  associatedProgramId: string;
  message: string;
  isSafari: boolean;

  timestamp: Timestamp;

  constructor(
    private auth: FirebaseAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private scheduleAccessor: ScheduleService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.scheduleData = this.scheduleAccessor.getScheduleById(this.id);
    this.scheduleData.subscribe((data) => {
      this.cycle = data.Cycle;
      this.day = data.Day;
      this.startTime = data.StartTime;
      this.endTime = data.EndTime;
      this.time = data.Time;
      this.associatedProgramId = data.Id;
    });

    if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) 
    {
      this.isSafari = true;
    }
  }

  generateTimestamp(): Timestamp {
    const changes = [];
    this.scheduleData.subscribe((schedule: ScheduledEvent) => {
      if (this.day !== schedule.Day) {
        changes.push(`Day:${schedule.Day}:${this.day}`);
      }
      if (this.time !== schedule.Time) {
        changes.push(`Time:${schedule.Time}:${this.time}`);
      }
      if (this.cycle !== schedule.Cycle) {
        changes.push(`Cycle:${schedule.Cycle}:${this.cycle}`);
      }
    });

    if (changes.length > 0) {
      return {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: changes.join(';'),
      };
    } else {
      return {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: null,
      };
    }
  }

  updateAll() {
    this.timestamp = this.generateTimestamp();
    if (this.startTime && this.cycle && this.day) {
      const startTime = moment(this.startTime, 'HH:mm').format('h:mma');
      let endTime = '';
      if (this.endTime) {
        endTime = ' - ' + moment(this.endTime, 'HH:mm').format('h:mma');
      }

      const changedSchedule: ScheduledEvent = {
        Id: this.associatedProgramId,
        Timestamp: this.timestamp,
        Day: this.day,
        StartTime: this.startTime,
        EndTime: this.endTime || null,
        Time: startTime + endTime,
        Cycle: this.cycle,
      };
      this.scheduleAccessor
        .getUpdatableEvents()
        .update(this.id, changedSchedule);
      this.back();
    } else {
      this.message = 'Start time, cycle, and day are required values';
    }
  }

  back() {
    this.location.back();
  }
}
