<p class="alert">{{ message }}</p>

<h3>
  <span>{{ organization?.Name + ' ' }}</span>
  <span class="sub">
    <a class="edit" routerLink="/home/editOrganization/{{ this.id }}" routerLinkActive="active">Edit</a>
    |
    <a class="delete" (click)="deleteOrg()">Delete</a>
  </span>
</h3>
<span *ngIf="showTimeStamp">
  <h4 style="font-weight: 100;">
    Last verification/change made on
    <strong>{{
      organization?.Timestamp.Time | date: 'medium'
    }}</strong>
    by <strong>{{ organization?.Timestamp.Email }}</strong>
  </h4>
</span>
<div class="content">
  <button class="verify" (click)="verifyInformation()">
    Verify information
  </button>
  <dl>
    <dt>Address</dt>
    <dd>{{ organization?.Address || '(none)' }}</dd>
    <dt>Phone Number</dt>
    <dd>{{ organization?.Call || '(none)' }}</dd>
    <dt>Website</dt>
    <dd>{{ organization?.Website || '(none)' }}</dd>
    <dt>Email</dt>
    <dd>{{ organization?.Email || '(none)' }}</dd>

    <dt>
      Associated Programs
      <!--      <a class="sub add" routerLink="/home/addProgram/{{(organization | async)?.key}}" routerLinkActive="active">Add Program</a>-->
    </dt>
    <dd *ngIf="!programs">
      (none)
    </dd>
    <dd *ngFor="let program of programs | async">
      <a routerLink="/home/program/{{ program.key }}" routerLinkActive="active">
        <span *ngIf="program.Summary != ''">{{ program.Summary }}</span>
        <span class="alert" *ngIf="program.Summary === ''">(Missing Summary)</span>
      </a>
    </dd>
    <dt>Translations:</dt>
    <dd *ngFor="let language of languages">
      <strong>{{language}}: </strong>
      {{ (translatedOrganizations[language] | async)?.Name || '(Empty)' }}
    </dd>
  </dl>
</div>
<button routerLink="/home/organizations" routerLinkActive="active">
  Back to Organizations
</button>