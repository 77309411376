import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '../firebase/translate.service';
import { AngularFireList } from 'angularfire2/database';
import { Program, Language } from '../Models/program.model';
import { Organization } from '../organizations/organization';
import { Category, ProviderCategory, Subcategory } from '../categories/category';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { Timestamp } from './timestamp';
import { GeocodeService } from '../Services/geocode.service';
import { ProgramService } from '../Services/program.service';
import { Coordinate } from '../Models/coordinate.model';
import { CategoryService } from '../Services/category.service';
import { SubcategoryService } from '../Services/subcategory.service';
import { OrganizationService } from '../Services/organization.service';
import { ProviderCategoryService } from 'app/Services/provider-category.service';

@Component({
  selector: 'app-edit-program',
  templateUrl: './editProgram.component.html',
  styleUrls: ['./editProgram.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EditProgramComponent implements OnInit {
  program: Program;

  organizations: Organization[];
  selectedOrganizationId: string;

  categories: Category[];
  selectedCategory: Category;
  selectedCategoryId: string;

  subcategoriesArray: Subcategory[] = [];
  subcategoriesList: AngularFireList<Subcategory>;
  selectedSubcategories: string[] = [];

  showEmptySubcategoriesWarning = false;

  providerCategories: ProviderCategory[] = [];
  selectedProviderCategories: string[] = [];

  coordinates: Coordinate;
  address: string;
  call: string;
  email: string;
  website: string;
  translations: Language[] = [];

  translatedPrograms = {};
  languages: string[];

  message: string;
  error = false;

  id: string;
  fromCat = false;

  timestamp: Timestamp;

  constructor(
    private auth: FirebaseAuthService,
    private categoryAccessor: CategoryService,
    private geocoder: GeocodeService,
    private location: Location,
    private organizationAccessor: OrganizationService,
    private programAccessor: ProgramService,
    private providerCategoryAccessor: ProviderCategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private subcategoryAccessor: SubcategoryService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.fromCat = params['fromCat'];
    });

    this.program = await this.programAccessor
      .getProgramById(this.id)
      .take(1)
      .toPromise();
    this.languages = await this.translate.parseLanguages();

    for (const language of this.languages) {
      this.translatedPrograms[
        language
      ] = await this.programAccessor.getSingleProgram(this.id, language);
      if (!this.translatedPrograms[language]) {
        // If program doesn't exist in language, set display values for edit page
        this.translatedPrograms[language] = {
          Summary: '',
          Description: '',
          userUpdated: false,
        };
      }
    }

    this.categoryAccessor
      .getUpdatableCategories()
      .snapshotChanges()
      .subscribe((snapshots) => {
        this.categories = [];
        snapshots.forEach((snapshot) => {
          const category: Category = snapshot.payload.val();
          category.key = snapshot.key;
          this.categories.push(category);
        });
      });

    this.organizationAccessor
      .getUpdatableOrganizations()
      .snapshotChanges()
      .subscribe((snapshots) => {
        this.organizations = [];
        snapshots.forEach((snapshot) => {
          const organization: Organization = snapshot.payload.val();
          organization.key = snapshot.key;
          this.organizations.push(organization);
        });
      });

    this.providerCategoryAccessor
      .getUpdatableCategories()
      .snapshotChanges()
      .subscribe((categories) => {
        this.providerCategories = [];
        categories.forEach((category) => {
          let c: ProviderCategory = category.payload.val();
          c.key = category.key;
          this.providerCategories.push(c);
        });
      });

    const data = this.program;
    if (data) {
      this.categoryAccessor
        .getCategoryById(data.CategoryId)
        .subscribe((category) => {
          this.fetchSubcategories();
        });

      this.selectedCategoryId = data.CategoryId;
      this.selectedOrganizationId = data.OrganizationId;

      if (data.SubcategoryIds) {
        this.selectedSubcategories = data.SubcategoryIds;
      } else if (data.SubcategoryId) {
        this.selectedSubcategories.push(data.SubcategoryId);
      }

      if (data.ProviderCategoryIds) {
        this.selectedProviderCategories = data.ProviderCategoryIds;
      }

      this.coordinates = data.Coordinates;
      this.address = data.Address || '';
      this.call = data.Call || '';
      this.email = data.Email || '';
      this.translatedPrograms['en'].Description = data.Description || '';
      this.translatedPrograms['en'].Summary = data.Summary || '';
      this.website = data.Website || '';
    }
  }

  categorySelected() {
    this.selectedSubcategories = [];
    this.fetchSubcategories();
  }

  fetchSubcategories() {
    this.subcategoriesList = this.subcategoryAccessor.getUpdatableSubcategories(
      this.selectedCategoryId
    );
    this.subcategoriesList.snapshotChanges().subscribe((subs) => {
      this.subcategoriesArray = [];
      subs.forEach((s) => {
        const subcategory = s.payload.val();
        subcategory.key = s.key;
        this.subcategoriesArray.push(subcategory);
      });
      if (this.subcategoriesArray.length) {
        this.showEmptySubcategoriesWarning = false;
        return;
      }
      this.showEmptySubcategoriesWarning = true;
    });
  }

  generateTimestamp(): Timestamp {
    const changes = [];

    const data = this.program;
    if (data) {
      if (this.address !== data.Address) {
        changes.push(`Address:${data.Address}:${this.address}`);
      }
      if (this.call !== data.Call) {
        changes.push(`Phone:${data.Call}:${this.call}`);
      }
      if (this.translatedPrograms['en'].Description !== data.Description) {
        changes.push(
          `Description:${data.Description}:${this.translatedPrograms['en'].Description}`
        );
      }
      if (this.email !== data.Email) {
        changes.push(`Email:${data.Email}:${this.email}`);
      }
      if (this.translatedPrograms['en'].Summary !== data.Summary) {
        changes.push(
          `Summary:${data.Summary}:${this.translatedPrograms['en'].Summary}`
        );
      }
      if (this.website !== data.Website) {
        changes.push(`Website:${data.Website}:${this.website}`);
      }
      if (this.selectedCategoryId !== data.CategoryId) {
        changes.push(`Category:${data.CategoryId}:${this.selectedCategoryId}`);
      }
      if (this.selectedOrganizationId !== data.OrganizationId) {
        changes.push(
          `Organization:${data.OrganizationId}:${this.selectedOrganizationId}`
        );
      }
      if (
        this.selectedSubcategories.map((subcategory) => subcategory['$key']) &&
        data.SubcategoryIds &&
        this.selectedSubcategories
          .map((subcategory) => subcategory['$key'])
          .sort()
          .join(',') !== data.SubcategoryIds.sort().join(',')
      ) {
        changes.push(
          `Subcategories:${
            data.SubcategoryIds
          }:${this.selectedSubcategories.map(
            (subcategory) => subcategory['$key']
          )}`
        );
      }
      if (
        this.selectedProviderCategories.map((category) => category['$key']) &&
        data.ProviderCategoryIds &&
        this.selectedProviderCategories
          .map((category) => category['$key'])
          .sort()
          .join(',') !== data.ProviderCategoryIds.sort().join(',')
      ) {
        changes.push(
          `ProviderCategories:${
            data.ProviderCategoryIds
          }:${this.selectedProviderCategories.map(
            (category) => category['$key']
          )}`
        );
      }
    }

    if (changes.length > 0) {
      return {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: changes.join(';'),
      };
    } else {
      return {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: null,
      };
    }
  }

  updateProgram() {
    this.timestamp = this.generateTimestamp();
    this.website = this.formatUrl(this.website.trim());
    this.translatedPrograms['en'].Summary = this.translatedPrograms[
      'en'
    ].Summary.trim();
    this.address = this.address.trim();
    this.call = this.call.trim();
    this.email = this.email.trim();
    this.translatedPrograms['en'].Description = this.translatedPrograms[
      'en'
    ].Description.trim();

    this.languages.forEach((language) => {
      this.translations[language] = {
        summary: this.translatedPrograms[language].Summary,
        description: this.translatedPrograms[language].Description,
        userUpdated: this.translatedPrograms[language].userUpdated,
      };
    });

    const updatedProgram: Program = {
      Website: this.formatUrl(this.website.trim()),
      Summary: this.translatedPrograms['en'].Summary.trim(),
      Address: this.address.trim(),
      Call: this.call.trim(),
      Email: this.email.trim(),
      Description: this.translatedPrograms['en'].Description.trim(),
      CategoryId: this.selectedCategoryId,
      OrganizationId: this.selectedOrganizationId,
      SubcategoryId: this.selectedSubcategories[0] || null,
      SubcategoryIds: this.selectedSubcategories,
      Timestamp: this.timestamp,
      ProviderCategoryIds: this.selectedProviderCategories,
    };

    if (
      this.translatedPrograms['en'].Summary.length > 5000 ||
      this.translatedPrograms['en'].Description.length > 5000
    ) {
      this.message =
        'Summary and Description must be less than 5000 characters';
      this.error = true;
    } else {
      const updatablePrograms = this.programAccessor.getUpdatablePrograms();

      updatablePrograms.update(this.id, updatedProgram);
      this.translate.translateProgramsByUserUpdated(
        updatedProgram,
        this.id,
        this.translations
      );

      if (this.address) {
        this.geocoder
          .geocodeNebraskaAddress(this.address)
          .subscribe((location) => {
            updatablePrograms.update(this.id, { Coordinates: location });
            this.translate.addCoordinates(this.id, location);
          });
      }
      this.router.navigate(['/home/program/' + this.id]);
    }
  }

  formatUrl(url: string) {
    if (!url.startsWith('http') && url.length > 0) {
      url = 'http://' + url;
    }
    return url;
  }

  cancel() {
    this.location.back();
  }
}
