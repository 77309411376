<h3>{{ subcat?.Name }}
  <span class="sub">
    <a
      class="edit"
      routerLink="/home/editSubcategory/{{ this.catId }}/{{ this.subcatId }}"
      routerLinkActive="active"
      >Edit</a
    >
  </span>
</h3>
<div class="content">
  <h4>Associated Programs:</h4>
  <ul>
    <li class="hover" *ngFor="let program of programsBySubcat | async">
      <a
        (click)="goto(program.CategoryId, program.key)"
        *ngIf="program.Summary !== ''"
        >{{ program.Summary }}</a
      >
      <a
        (click)="goto(program.CategoryId, program.key)"
        class="alert"
        *ngIf="program.Summary === ''"
        >(Missing Summary)</a
      >
    </li>
  </ul>
  <h4>Translations:</h4>
  <ul *ngFor="let language of languages">
    <li>
      <strong>{{language}}:</strong> {{ translatedSummaries[language] || '(Empty)' }}
    </li>
  </ul>
  <button (click)="translateSubcat()">Translate Subcategory</button>
</div>
<button routerLink="/home/subcategories/{{ catId }}" routerLinkActive="active">
  Back to Categories
</button>
