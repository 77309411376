import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from './firebase/firebaseAuth.service';
import { UserService } from './Services/user.service';
import { ThemeService } from './Services/theme.service';
import { Location, LocationNameMapper } from './Models/enums/location';
import { PreferencesService } from './Services/preferences.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { UserLocation } from './admin/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  adminLogo: string;
  currentEntityKey: number;
  entities: UserLocation[];
  locationNameMapper = LocationNameMapper;
  constructor(
    public auth: FirebaseAuthService,
    public afAuth: AngularFireAuth,
    private userAccessor: UserService,
    private themeAccessor: ThemeService,
    private ps: PreferencesService
  ) { }

  logout() {
    this.ps.setLocation(Location.NotSet);
    this.currentEntityKey = Location.NotSet;
    this.auth.logout();
  }

  changeLocation(entity) {
    this.ps.setLocation(entity);
    location.reload();
  }

  async ngOnInit() {
    this.userAccessor.initializeLists();
    const users = await this.userAccessor.parseUser();

    this.currentEntityKey = this.ps.getLocationId();

    this.afAuth.user.subscribe(user => {
      if (user) {
        const currentUser = users.find(u => u.email === user.email);
        if(currentUser){
          this.entities = currentUser.entities;
          if (!this.currentEntityKey || (this.currentEntityKey && this.currentEntityKey === Location.NotSet)) {
            this.currentEntityKey = this.entities[0].key;
            this.ps.setLocation(this.currentEntityKey)
          }
  
          this.themeAccessor.getThemeElement('adminLogo').subscribe((element) => {
            if (!element) {
              location.reload()
            }
  
            if (element) {
              console.log(element)
              this.adminLogo = element;
            }
          });
        }
      }
    });
  }

}
