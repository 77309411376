import { Component, OnInit } from '@angular/core';
import { AngularFireList } from 'angularfire2/database';
import { Program } from '../Models/program.model';
import { Organization } from '../organizations/organization';
import { Category, Subcategory } from '../categories/category';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { TranslateService } from '../firebase/translate.service';
import { coordinate } from './coordinate';
import { UserService } from '../Services/user.service';
import { ProgramService } from '../Services/program.service';
import { OrganizationService } from '../Services/organization.service';
import { CategoryService } from '../Services/category.service';

// This page is not currently used but may be added back later
@Component({
  selector: 'add-program',
  templateUrl: './addProgram.component.html',
})
export class AddProgramComponent implements OnInit {
  programs: AngularFireList<Program>;
  organizations: AngularFireList<Organization>;
  categories: Category[];
  subcategories: AngularFireList<Subcategory>;

  id: string;

  address = '';
  call = '';
  categoryId = '';
  subcategoryIds = [];
  description = '';
  email = '';
  organizationId: string;
  subcategoryId = '';
  summary = '';
  website = '';
  summaryError: string;
  descriptionError: string;
  categoryError: string;
  error = false;

  constructor(
    private translate: TranslateService,
    private auth: FirebaseAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private userAccessor: UserService,
    private programAccessor: ProgramService,
    private organizationAccessor: OrganizationService,
    private categoryAccessor: CategoryService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.programs = this.programAccessor.getUpdatablePrograms();
    this.organizations = this.organizationAccessor.getUpdatableOrganizations();
    this.categoryAccessor
      .getUpdatableCategories()
      .valueChanges()
      .subscribe((data) => {
        this.categories = data;
      });
    if (this.id !== undefined) {
      this.organizationId = this.id;
    } else {
      this.organizationId = '';
    }
  }

  updateSubcategories() {
    this.subcategoryIds = [];
    this.subcategoryId = '';
    this.subcategories = this.userAccessor.findList(
      `/1/Categories/${this.categoryId}/Subcategories`,
      'Name'
    );
  }

  add() {
    this.summaryError = '';
    this.descriptionError = '';
    this.categoryError = '';
    this.error = false;
    if (this.summary === '') {
      this.summaryError = 'Summary is required';
      this.error = true;
    }
    if (this.description === '') {
      this.descriptionError = 'Description is required';
      this.error = true;
    }
    if (this.categoryId === '') {
      this.categoryError = 'Category is required';
      this.error = true;
    }
    if (!this.error) {
      let timestamp = {
        Time: new Date().getTime(),
        Email: this.auth.afAuth.auth.currentUser.email,
        Changes: `Address::${this.address};Call::${this.call};CategoryId::${this.categoryId};Description::${this.description};Email::${this.email};OrganizationId::${this.organizationId};SubcategoryId::${this.subcategoryIds[0]};SubcategoryIds::${this.subcategoryIds};Summary::${this.summary};Website::${this.website};`,
      };

      this.router.navigate(['/home/org-detail/' + this.id]);
    }
  }
}
