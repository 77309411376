export class CategoryBase {
  Name: string;
  key?: string;
  userUpdated?: boolean;
}

export class Category extends CategoryBase {
  Icon?: string;
  Subcategories?: Subcategory[];
}

export class Subcategory extends CategoryBase {
}

export class ProviderCategory extends CategoryBase {
  Icon?: string;
}
