import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../firebase/translate.service';
import { Program } from '../Models/program.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subcategory } from './category';
import { Observable } from 'rxjs';
import { ProgramService } from '../Services/program.service';
import { SubcategoryService } from '../Services/subcategory.service';

@Component({
  selector: 'sub-program',
  templateUrl: './subProgram.component.html',
})
export class SubProgramComponent implements OnInit {
  programsBySubcat: Observable<Program[]>;
  catId: string;
  subcatId: string;
  subcat: Subcategory;
  subcatEs: Subcategory;
  subcatVi: Subcategory;
  subcatAr: Subcategory;
  subcatSo: Subcategory;
  subCategory: Subcategory;
  languages: string[];
  translatedSummaries = {};

  constructor(
    private translate: TranslateService,
    private programAccessor: ProgramService,
    private router: Router,
    private route: ActivatedRoute,
    private subcategoryAccessor: SubcategoryService
  ) {

  }

  goto(catId: string, key: string) {
    this.programAccessor.getProgramById(key);
    this.router.navigate(['/home/program/' + key + '/true']);
  }

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.subcatId = params['subcatId'];
      this.catId = params['catId'];
      this.programsBySubcat = this.programAccessor.getProgramsBySubcategory(
        this.subcatId,
        this.catId
      );
      this.subcat = await this.subcategoryAccessor.getSubcategoryById(
        this.catId,
        this.subcatId
      ).take(1)
        .toPromise();
      this.languages = await this.translate.parseLanguages();

      this.languages.forEach((language) => {
        this.subcategoryAccessor
          .getSubcategoryById(
            this.catId,
            this.subcatId, language)
          .subscribe((subCat) => {
            if (subCat) {
              this.translatedSummaries[language] = subCat?.Name || '';
            }
          });
      });
    });
  }

  translateSubcat() {
    this.translate.translateSubCategory(this.subcat, true, this.catId, this.subcatId);
  }
}
