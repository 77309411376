<h3>Search</h3>
<div>
  <label>Search: </label>
  <input #searchBox id="search-box" (keyup)="search(searchBox.value)" />
  <a class="sub" (click)="more()"
    ><span *ngIf="!options">Search by Category</span
    ><span *ngIf="options">Use Search Bar</span></a
  >
  |
  <a class="sub" (click)="searchSchedule()"
    ><span *ngIf="!scheduleSearch">Search by Schedule</span
    ><span *ngIf="scheduleSearch">Use Search Bar</span></a
  >
  <div id="radios" *ngIf="!options && !scheduleSearch">
    <input
      type="radio"
      name="list"
      value="organizations"
      [(ngModel)]="selected"
      (change)="changed()"
    />Only Organizations
    <input
      type="radio"
      name="list"
      value="programs"
      [(ngModel)]="selected"
      (change)="changed()"
    />Only Programs
    <input
      type="radio"
      name="list"
      value="allSearch"
      [(ngModel)]="selected"
      (change)="changed()"
    />Show Both
  </div>
  <div class="content" *ngIf="options">
    <label>Search by Category:</label>
    <mat-select
      (selectionChange)="searchByCat()"
      [(value)]="categoryId"
      name="categories"
    >
      <mat-option *ngFor="let cat of categories" value="{{ cat.key }}">
        {{ cat.Name }}
      </mat-option>
    </mat-select>
    <br />
    <span *ngIf="categoryId">
      <label>Search by Subcategory:</label>
      <mat-select
        (selectionChange)="searchBySubcat()"
        [(value)]="subcategoryId"
        name="subcateogries"
      >
        <mat-option *ngFor="let sub of subcategories" value="{{ sub.key }}">
          {{ sub.Name }}
        </mat-option>
      </mat-select>
    </span>
  </div>
  <div class="content" *ngIf="scheduleSearch">
    <br />
    <label>Search by Cycle:</label>
    <select [(ngModel)]="cycle" (ngModelChange)="searchByCycle()">
      <option [value]="''"></option>
      <option *ngFor="let cycle of cycles" [value]="cycle">{{ cycle }}</option>
    </select>
    <br />
    <label>Search by Day:</label>
    <select [(ngModel)]="day" (ngModelChange)="searchByDay()">
      <option [value]="''"></option>
      <option *ngFor="let day of days" [value]="day">{{ day }}</option>
    </select>
    <br />
    <label>Search by Time:</label>
    <input #timeBox id="search-box" (keyup)="searchByTime(timeBox.value)" />
  </div>
</div>
<br />
<div *ngIf="scheduleSearch">
  <p class="length">{{ chosenSchedule.length }} Matching Items</p>
  <div class="table">
    <table>
      <tr>
        <th>Matching Scheduled Events</th>
      </tr>
      <tr *ngFor="let event of chosenSchedule">
        <td id="program" (click)="goToProgram(event.Id)">
          <p>
            <span class="bold">Program Name: </span
            ><span *ngIf="event.name != ''">{{ event.name }}</span
            ><span *ngIf="event.name === ''">(No program name)</span>
          </p>
          <p><span class="bold">Cycle: </span>{{ event.Cycle }}</p>
          <p><span class="bold">Day: </span>{{ event.Day }}</p>
          <p><span class="bold">Time: </span>{{ event.Time }}</p>
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="searching">
  <div class="table" *ngIf="!options">
    <table
      *ngIf="
        searchResultType === 'organizationSearch' ||
        searchResultType === 'allSearch'
      "
    >
      <p class="length">{{ searchOrg.length }} Matching Items</p>
      <tr>
        <th>Matching Organizations</th>
      </tr>
      <tr *ngFor="let org of searchOrg; trackBy: track">
        <td
          id="{{ org.key }}"
          routerLink="/home/org-detail/{{ org.key }}/{{ searchBox.value }}"
        >
          <span class="{{ org.Name == '' ? 'alert' : '' }}">
            {{ org.Name == '' ? '(Missing Name)' : org.Name }}
          </span>
        </td>
      </tr>
    </table>
  </div>
  <div class="table">
    <table
      *ngIf="
        searchResultType === 'programSearch' ||
        searchResultType === 'allSearch' ||
        options
      "
    >
      <p class="length">{{ searchProgram.length }} Matching Items</p>
      <tr>
        <th>Matching Programs</th>
      </tr>
      <tr *ngFor="let program of searchProgram; trackBy: track">
        <td routerLink="/home/program/{{ program.key }}">
          <span class="{{ program.Summary == '' ? 'alert' : '' }}">
            {{ program.Summary == '' ? '(Missing Name)' : program.Summary }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</div>
