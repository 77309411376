import { Component, OnInit } from '@angular/core';
import { AngularFireList } from 'angularfire2/database';
import { Organization } from '../organizations/organization';
import { Program } from '../Models/program.model';
import { Category, Subcategory } from '../categories/category';
import {
  ScheduledEvent,
  CycleList,
  DayList,
} from '../scheduled/scheduledEvent';
import { Router } from '@angular/router';
import { compareTwoStrings } from 'string-similarity';
import { ProgramService } from '../Services/program.service';
import { OrganizationService } from '../Services/organization.service';
import { CategoryService } from '../Services/category.service';
import { SubcategoryService } from '../Services/subcategory.service';
import { ScheduleService } from '../Services/schedule.service';

export enum SearchResultType {
  organizationSearch = 'organizationSearch',
  programSearch = 'programSearch',
  allSearch = 'allSearch',
}

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  options = false;
  searchProgram: Program[] = [];
  searchOrg: Organization[] = [];

  chosenSchedule: ScheduledEvent[] = [];
  searching = false;

  subcategories: Subcategory[] = [];
  categoryId: string = '';
  subcategoryId: string = '';
  selected: string;
  scheduleSearch: boolean = false;
  cycle: string;
  day: string;
  time: string;
  cycles = CycleList;
  days = DayList;

  searchBoxValue: string;

  searchResultType = SearchResultType.allSearch;

  cycleSearch = false;
  daySearch = false;
  timeSearch = false;

  organizations: Organization[];
  programs: Program[];
  categories: Category[];
  schedules: ScheduledEvent[];

  timeout = null;

  constructor(
    private router: Router,
    private programAccessor: ProgramService,
    private organizationAccessor: OrganizationService,
    private categoryAccessor: CategoryService,
    private subcategoryAccessor: SubcategoryService,
    private scheduleAccessor: ScheduleService
  ) {}

  ngOnInit() {
    this.organizations = this.organizationAccessor.getCachedOrganizations();
    this.programs = this.programAccessor.getCachedPrograms();
    this.categories = this.categoryAccessor.getCachedCategories();
    this.schedules = this.scheduleAccessor.getAllEvents();
  }

  goToProgram(programKey: string) {
    this.router.navigate(['/home/program/' + programKey]);
  }

  reset() {
    this.chosenSchedule = [];
    this.cycleSearch = false;
    this.daySearch = false;
    this.timeSearch = false;
    this.cycle = '';
    this.day = '';
    this.time = '';
  }

  searchSchedule() {
    if (this.scheduleSearch) {
      this.reset();
    } else {
      this.options = false;
      this.searching = false;
    }
    this.scheduleSearch = !this.scheduleSearch;
  }

  searchByCycle() {
    this.cycleSearch = true;
    this.searchSchedules();
  }

  searchByDay() {
    this.daySearch = true;
    this.searchSchedules();
  }

  searchByTime(value: string) {
    this.time = value;
    this.timeSearch = value !== '';
    this.searchSchedules();
  }

  searchSchedules() {
    this.chosenSchedule = [];
    this.schedules.forEach((schedule) => {
      if (
        !(this.cycleSearch && schedule['Cycle'] !== this.cycle) &&
        !(this.daySearch && schedule['Day'] !== this.day) &&
        !(
          this.timeSearch &&
          !schedule['Time'].toLowerCase().includes(this.time.toLowerCase())
        )
      ) {
        this.programs.forEach((program) => {
          if (program['key'] === schedule['Id']) {
            schedule['name'] = program['Summary'];
          }
        });
        this.chosenSchedule.push(schedule);
      }
    });
  }

  changed() {
    this.searching = true;

    switch (this.selected) {
      case 'organizations':
        this.searchResultType = SearchResultType.organizationSearch;
        break;
      case 'programs':
        this.searchResultType = SearchResultType.programSearch;
        break;
      case 'allSearch':
        this.searchResultType = SearchResultType.allSearch;
        break;
    }
  }

  search(value: string) {
    const self = this;
    let searchWait = 300;

    // Waits until there has been [searchWait] seconds before searching
    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      if (!value) {
        self.searching = false;
        self.searchOrg = [];
        self.searchProgram = [];
        return;
      }

      let lowerValue = value.toLowerCase();

      self.reset();

      self.searching = true;
      self.options = false;
      self.scheduleSearch = false;

      self.searchOrg = [];
      self.searchProgram = [];

      self.searchOrg = self.organizations.filter(
        (org) =>
          SearchComponent.fuzzMatch(org.Name, lowerValue) ||
          SearchComponent.fuzzMatch(org.Address, lowerValue) ||
          SearchComponent.fuzzMatch(org.key, lowerValue) ||
          org.key === lowerValue
      );

      self.searchProgram = self.programs.filter(
        (prog) =>
          SearchComponent.fuzzMatch(prog.Description, lowerValue) ||
          SearchComponent.fuzzMatch(prog.Summary, lowerValue) ||
          SearchComponent.fuzzMatch(prog.Address, lowerValue) ||
          prog.key === lowerValue.trim() ||
          prog.OrganizationId === lowerValue.trim()
      );
    }, searchWait);
  }

  static fuzzMatch(prog, term) {
    if (!prog || !term) {
      return false;
    }
    let termAr = term
      .toLowerCase()
      .replace(/[^a-zA-Z0-9:]/g, ' ')
      .split(' ');
    let progAr = prog
      .toLowerCase()
      .replace(/[^a-zA-Z0-9:]/g, ' ')
      .split(' ');

    outer: for (let j = 0; j < termAr.length; j++) {
      for (let i = 0; i < progAr.length; i++) {
        let match = compareTwoStrings(progAr[i], termAr[j]);
        let match2 = compareTwoStrings(
          progAr[i].replace(/[aeiouy]/gi, ''),
          termAr[j].replace(/[aeiouy]/gi, '')
        );
        if (match >= 0.7 || match2 >= 0.8) {
          continue outer;
        }
      }
      return false;
    }

    return true;
  }

  track(index, item) {
    return item.key;
  }

  searchByCat() {
    this.subcategories = [];
    this.subcategoryAccessor
      .getUpdatableSubcategories(this.categoryId)
      .snapshotChanges()
      .subscribe((snapshots) => {
        snapshots.forEach((snapshot) => {
          const subcat: Subcategory = snapshot.payload.val();
          subcat.key = snapshot.key;
          this.subcategories.push(subcat);
        });
        this.searchProgram = [];
        this.searching = true;
        this.options = true;
        this.subcategoryId = '';

        this.searchProgram = this.programs.filter(
          (y) => y.CategoryId === this.categoryId
        );
      });
  }

  searchBySubcat() {
    this.searchProgram = [];
    this.searching = true;
    this.options = true;

    this.searchProgram = this.programs.filter(
      (y) =>
        y.CategoryId === this.categoryId ||
        y.SubcategoryId === this.subcategoryId
    );
  }

  more() {
    this.options = !this.options;
    this.searching = false;
    this.scheduleSearch = false;
    this.reset();
  }
}
