import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { TranslateService } from '../firebase/translate.service';

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [FirebaseAuthService, TranslateService],
})
export class CoreModule {}
