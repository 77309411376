import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from 'app/Services/theme.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-theme-customization',
  templateUrl: './theme-customization.component.html',
  styleUrls: ['./theme-customization.component.css'],
})
export class ThemeCustomizationComponent implements OnInit {
  displayedColumns = ['Name', 'Value', 'Buttons'];

  dataSource: MatTableDataSource<any>;
  themeElements: Object[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private themeAccessor: ThemeService) {}

  setSortingAccessor() {
    this.dataSource.sortingDataAccessor = (item) => {
      return this.getDisplayName(item);
    };
    this.sort.direction = 'asc';
    this.sort.active = 'Name';
  }

  getDisplayName(element: any): string {
    return this.caps(element['Name'].trim());
  }

  saveChanges() {
    const newObject = {};

    this.themeElements.forEach((element) => {
      newObject[element['Name']] = element['Value'];
    });

    this.themeAccessor.saveThemeElements(newObject);
  }

  uploadFileToStorage(file: File, elementName: string) {
    const filePath = 'themeFile/' + uuid();
    this.themeAccessor.uploadFileToStorage(filePath, file).subscribe(() => {
      let themeElement = this.themeElements.find((item) => {
        return item['Name'] == elementName;
      });
      if (themeElement) {
        this.themeAccessor.getDownloadUrl(filePath).subscribe((url) => {
          const newObject = {};
          const oldImage = themeElement['Value'];
          themeElement['Value'] = url;
          newObject[themeElement['Name']] = themeElement['Value'];
          this.themeAccessor.saveThemeElements(newObject);
          if (oldImage.search(filePath) === -1) {
            this.themeAccessor.deleteFileFromStorage(oldImage);
          }
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  caps(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  isString(value) {
    return typeof value === 'string' && !this.isHex(value);
  }

  isImage(element) {
    return (
      typeof element['Value'] === 'string' &&
      element['Value'].startsWith('https://firebasestorage')
    );
  }

  isHex(value) {
    if (typeof value === 'string') {
      return value.startsWith('#');
    }
  }

  ngOnInit() {
    this.themeAccessor.getThemeElements().subscribe((elements) => {
      this.themeElements = elements;
      this.dataSource = new MatTableDataSource(this.themeElements);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.setSortingAccessor();
    });
  }
}
