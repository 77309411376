<h3>Edit Organization</h3>
<h4>An English Program name is required. Any languages with 'Automatically update' checked will automatically translate
  through google translate when the program is updated</h4>

<div>
  <div class="content">
    <dl>
    <div *ngIf="translatedOrganization['en']">
      <dt>
        <label>English:</label>
      </dt>
      <dd>
        <label>Organization Name</label>
      </dd>
      <dd>
        <input type="text" *ngIf="true" [ngClass]="{ error: error }" [(ngModel)]="translatedOrganization['en'].Name" />
      </dd>
    </div>
      <div *ngFor="let language of languages">
        <div *ngIf="language !== 'en' && translatedOrganization[language]">
          <dt>
            <label>{{language}}:</label>
          </dt>
          <dd>
            <input class="checkbox" type="checkbox" [checked]="!translatedOrganization[language]?.userUpdated"
              (change)="translatedOrganization[language].userUpdated = !translatedOrganization[language]?.userUpdated" />
            Automatically Update
          </dd>
          <dd>
            <label>Organization Name</label>
          </dd>
          <dd>
            <input type="text" [ngClass]="{ error: error }" [(ngModel)]="translatedOrganization[language].Name" />
          </dd>
        </div>
      </div>
      <dt>
        <Label>Address:</Label>
      </dt>
      <dd>
        <textarea type="text" class="address" [(ngModel)]="address"></textarea>
      </dd>
      <dt>
        <Label>Phone Number:</Label>
      </dt>
      <dd>
        <input type="text" [(ngModel)]="phoneNumber" />
      </dd>
      <dt>
        <Label>Website:</Label>
      </dt>
      <dd>
        <input type="text" [(ngModel)]="website" />
      </dd>
      <dt>
        <Label>Email:</Label>
      </dt>
      <dd>
        <input type="text" [(ngModel)]="email" />
      </dd>
    </dl>
  </div>
  <span>
    <p class="alert">{{ message }}</p>
    <button
      routerLink="/home/org-detail/{{ id }}"
      routerLinkActive="active"
      class="cancel"
    >
      Cancel
    </button>
    <button
      routerLink="/home/org-detail/{{ id }}"
      routerLinkActive="active"
      (click)="updateAll()"
      class="submit-update"
    >
      Update
    </button>
  </span>
</div>
