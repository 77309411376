import { Component, OnInit } from '@angular/core';
import { Category, Subcategory } from './category';
import { Program } from '../Models/program.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '../firebase/translate.service';
import { Observable } from 'rxjs';
import { Organization } from 'app/organizations/organization';
import { CategoryService } from '../Services/category.service';
import { SubcategoryService } from '../Services/subcategory.service';
import { ProgramService } from '../Services/program.service';
import { OrganizationService } from '../Services/organization.service';
import { map } from 'rxjs/operators';
import { AngularFireList } from 'angularfire2/database';

@Component({
  selector: 'subcategories',
  templateUrl: './subcategories.component.html',
})
export class SubcategoriesComponent implements OnInit {
  category: Category;
  subcategories: Subcategory[];
  subcategoriesFireList: AngularFireList<Subcategory>;
  programs: Program[];
  programsByCat: Program[];
  id: string;
  name: string;
  path: string;
  adding = false;
  message: string;
  errorMessage: string;
  error = false;
  showPrograms = true;
  organizations: Observable<Organization[]>;
  languages: string[];
  translatedCategories = {};

  constructor(
    private translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute,
    private categoryAccessor: CategoryService,
    private subcategoryAccessor: SubcategoryService,
    private programAccessor: ProgramService,
    private organizationAccessor: OrganizationService
  ) {}

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.languages = await this.translate.parseLanguages();

    this.category = await this.categoryAccessor.findCategory(this.id);
    this.programs = this.programAccessor.getCachedPrograms();
    this.programsByCat = this.programs.filter(
      (program) => program.CategoryId === this.id
    );

    this.languages.forEach((language) => {
      this.categoryAccessor
        .getCategoryById(this.id, language)
        .subscribe((category) => {
          if (category) {
            this.translatedCategories[language] = category.Name;
          } else {
            this.translatedCategories[language] = '';
          }
        });
    });

    this.subcategories = [];
    this.fetchSubcategories();

    this.path = '1/Categories/' + this.id + '/Subcategories';
    this.organizations = this.organizationAccessor.getOrganizations();
  }

  fetchSubcategories() {
    this.subcategoriesFireList = this.subcategoryAccessor.getUpdatableSubcategories(
      this.id
    );
    this.subcategoriesFireList.snapshotChanges().subscribe((subs) => {
      subs.forEach((s) => {
        const subcategory = s.payload.val();
        subcategory.key = s.key;
        this.subcategories.push(subcategory);
      });
    });
  }

  seePrograms() {
    this.showPrograms = true;
  }

  hide() {
    this.showPrograms = false;
  }

  onKey(value: string) {
    this.name = value;
    this.error = false;
  }

  addSubCat() {
    this.adding = true;
  }

  cancel() {
    this.adding = false;
    this.name = '';
    this.errorMessage = '';
    this.error = false;
  }

  getOrganizationNameById(OrganizationId) {
    return this.organizations.pipe(
      map((organizations) =>
        organizations.find(
          (organization) => organization.key === OrganizationId
        )
      )
    );
  }

  add() {
    let name = this.name;
    this.error = false;
    if (!name) {
      this.errorMessage = 'Name is required';
      this.error = true;
    } else {
      this.subcategoryAccessor
        .getUpdatableSubcategories(this.id)
        .push({ Name: name })
        .then((snap) => {
          this.translate.translateSubCategory(
            { Name: name },
            true,
            this.id,
            snap.key
          );
        });
      this.subcategories = [];
      this.adding = false;
      this.name = '';
      this.errorMessage = '';
      this.error = false;
      this.message = 'Success';
    }
  }

  hasDependent(subcategoryId: string): boolean {
    this.programs.forEach((program) => {
      if (program.CategoryId === this.id) {
        if (
          (program.SubcategoryIds &&
            program.SubcategoryIds.includes(subcategoryId)) ||
          (program.SubcategoryId && program.SubcategoryId === subcategoryId)
        ) {
          return true;
        }
      }
    });
    return false;
  }

  delete(value: string) {
    if (this.hasDependent(value)) {
      this.message = 'Subcategory cannot be deleted if in use';
    } else {
      this.message = 'Success';
      this.subcategories = [];
      this.translate.removeSubcat(value, this.id);
      this.subcategoryAccessor.getUpdatableSubcategories(this.id).remove(value);
    }
  }
  translateCategory() {
    this.translate.translateCategory(this.category, this.id);
  }
}
