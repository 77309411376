import { Component, OnInit } from '@angular/core';
import { AngularFireList } from 'angularfire2/database';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { User, UserLocation } from './user';
import { UserService } from '../Services/user.service';
import { PreferencesService } from 'app/Services/preferences.service';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  users: AngularFireList<any>;
  usersList: User[] = [];
  admins: AngularFireList<any>;
  adminList: any[];
  adding: boolean = false;
  email: string;
  password: string;
  userToDelete: string;
  adminKey: string;
  currentPassword: string;
  cpUID: string;
  cpEmail: string;
  newPassword: string;
  isAdmin: boolean = false;
  delete: boolean = false;
  errorMessage: string;
  error: boolean = false;
  changingPassword: boolean = false;
  locationCode: string;

  constructor(
    public auth: FirebaseAuthService,
    private userAccessor: UserService,
    private preferencesService: PreferencesService, 
  ) {}

  enableUser(email, uid, key) {
    this.users.update(key, { isEnabled: true });
    this.errorMessage = email + ' enabled';
  }

  disableUser(email, uid, key) {
    this.users.update(key, { isEnabled: false });
    this.errorMessage = email + ' disabled';
  }

  deleting(key: string) {
    this.userToDelete = key;
    for (var i = 0; i < this.adminList.length; i++) {
      if (this.adminList[i]['uid'] === this.auth.user.uid) {
        this.adminKey = this.adminList[i]['key'];
      }
    }
    this.delete = true;
  }

  changePasswordPopup(uid, email) {
    this.changingPassword = true;
    this.cpUID = uid;
    this.cpEmail = email;
  }

  sendPasswordResetEmail(email) {
    this.auth.sendPasswordResetEmail(email);
  }

  changePassword() {
    this.error = false;
    if (this.currentPassword && this.newPassword) {
      this.auth.changePassword(
        this.cpUID,
        this.cpEmail,
        this.currentPassword,
        this.newPassword
      );

      this.currentPassword = '';
      this.newPassword = '';
      this.changingPassword = false;
    } else {
      this.error = true;
      this.errorMessage = 'All fields are required';
    }
  }

  cancelPasswordChange() {
    this.changingPassword = false;
  }

  add() {
    this.adding = true;
    this.errorMessage = '';
    this.error = false;
  }

  notDeleting() {
    this.delete = false;
  }

  deleteUser() {
    this.auth.deleteUser(this.userToDelete, this.adminKey);
    this.delete = false;
  }

  addUser() {
    this.error = false;
    const entities = [];
    const locationKey = this.preferencesService.getLocationId();
    const userLocation: UserLocation = {
      key: locationKey,
      locationCode: this.locationCode
    }
    entities.push(userLocation);
    if (!this.email || !this.password || !this.currentPassword) {
      this.error = true;
    }
    if (this.error) {
      this.errorMessage = 'All fields are required';
    } else {
      this.auth.createUser(
        this.email,
        this.password,
        this.isAdmin,
        this.auth.user.email,
        this.currentPassword,
        entities
      );
      this.adding = false;
      this.errorMessage = undefined;
    }
    this.email = '';
    this.password = '';
    this.currentPassword = '';
  }

  setAdmin() {
    this.isAdmin = !this.isAdmin;
  }

  makeAdmin(email: string, uid: string, key: string) {
    this.admins.push({ uid: uid });
    this.users.update(key, { isAdmin: true });
    this.userAccessor.checkAdmin();
    this.errorMessage = 'Admin status granted to ' + email;
  }

  removeAdmin(email: string, uid: string, key: string) {
    let adminKey: string;
    adminKey = this.adminList.find((x) => x.uid == uid).key;

    if (adminKey) {
      this.admins.remove(adminKey);
      this.users.update(key, { isAdmin: false });
      this.userAccessor.checkAdmin();
      this.errorMessage = 'Admin status removed for ' + email;
    }
  }

  cancel() {
    this.adding = false;
    this.errorMessage = undefined;
    this.error = false;
    this.email = '';
    this.password = '';
    this.currentPassword = '';
  }

  ngOnInit() {
    this.auth.badMessage = '';
    this.auth.goodMessage = '';
    this.locationCode = this.preferencesService.getLocationCode();
    this.users = this.userAccessor.getUpdatableUsers();

    this.users.snapshotChanges().subscribe((snapshots) => {
      this.usersList = [];
      snapshots.forEach((snapshot) => {
        const user: User = snapshot.payload.val();
        user.key = snapshot.key;
        if (user.entities && user.entities.some(x => x.locationCode === this.locationCode)) {
          this.usersList.push(user);
        }
      });

      this.usersList.sort((a, b) => {
        return a.email < b.email ? -1 : a.email > b.email ? 1 : 0;
      });
    });

    this.admins = this.userAccessor.admins;
    this.adminList = this.userAccessor.adminList;
    this.userAccessor.admins.snapshotChanges().subscribe((snapshots) => {
      this.adminList = [];
      snapshots.forEach((snapshot) => {
        const admin: any = snapshot.payload.val();
        admin.key = snapshot.key;
        this.adminList.push(admin);
      });
    });
  }
}
