<h5 class="alert" *ngIf="message">{{ message }}</h5>
<h3>
  {{ category?.Name }}
  <span class="sub">
    <a class="sub add" (click)="addSubCat()">Add Subcategory</a> |
    <a
      class="edit"
      routerLink="/home/editCategory/{{ this.id }}"
      routerLinkActive="active"
      >Edit</a
    >
    |
    <a
      *ngIf="!showPrograms; else hiddenPrograms"
      class="normalLink"
      (click)="seePrograms()"
      >See All Programs</a
    >
    <ng-template #hiddenPrograms>
      <a class="normalLink" (click)="hide()">Hide All Programs</a>
    </ng-template>
  </span>
</h3>
<div class="content">
  <div class="update" *ngIf="adding">
    <h4>Add Subcategory</h4>
    <p class="alert">{{ errorMessage }}</p>
    <label>Name</label>
    <input [ngClass]="{ error: error }" #box (keyup)="onKey(box.value)" />
    <br />
    <button (click)="add()">Add</button>
    <br />
    <a class="sub" (click)="cancel()">Cancel</a>
  </div>
  <h4>Subcategories:</h4>
  <span *ngIf="subcategories?.length === 0"> (none) </span>
  <ul>
    <span *ngFor="let subcategory of subcategories">
      <li>
        <a
          (click)="
            this.router.navigate([
              '/home/subProgram/' + this.id + '/' + subcategory.key
            ])
          "
          >{{ subcategory.Name }}</a
        >
      </li>
      <a class="sub delete" (click)="delete(subcategory.key)">Delete</a>
    </span>
  </ul>
</div>

<div *ngIf="showPrograms" class="content">
  <h4>Associated Programs:</h4>
  <ul>
    <li class="hover" *ngFor="let program of programsByCat">
      <a
        (click)="
          this.router.navigate(['/home/program/' + program.key + '/true'])
        "
      >
        <strong>{{
          program.OrganizationId
            ? (getOrganizationNameById(program.OrganizationId) | async)?.Name
            : ''
        }}</strong>
        -
        <span *ngIf="program.Summary">{{ program.Summary }}</span>
        <span *ngIf="!program.Summary" class="alert">Missing Summary</span>
      </a>
    </li>
  </ul>
</div>

<div class="content">
  <h4>Translations:</h4>
  <ul *ngFor="let language of languages">
    <li>
      <strong>{{ language }}:</strong> {{ translatedCategories[language] }}
    </li>
  </ul>
  <button (click)="translateCategory()">Translate Category</button>
</div>

<button routerLink="/home/categories" routerLinkActive="active">
  Back to Categories
</button>
