import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FirebaseAuthService } from './firebase/firebaseAuth.service';
import { UserService } from './Services/user.service';

@Injectable()
export class AdminAuthGuard implements CanActivate {
  isAdmin: boolean = false;

  constructor(
    private authService: FirebaseAuthService,
    private router: Router,
    private userAccessor: UserService
  ) {}

  canActivate(): boolean {
    return this.checkLogin();
  }

  checkLogin(): boolean {
    this.isAdmin = false;
    let list: any[] = this.userAccessor.adminList;
    for (let i = 0; i < list.length; i++) {
      if (list[i]['uid'] === this.authService.user.uid) {
        this.isAdmin = true;
      }
    }
    if (this.authService.isLoggedIn && this.isAdmin) {
      return true;
    } else {
      if (this.authService.isLoggedIn) {
        this.router.navigate(['/not-authorized']);
      } else {
        this.router.navigate(['/login']);
      }
      return false;
    }
  }
}
