<p class="goodAlert">{{ auth.goodMessage }}</p>
<p class="alert">{{ auth.badMessage }}</p>
<p class="alert">{{ errorMessage }}</p>
<h3>
  {{ auth.isAdmin ? 'Accounts' : auth?.user?.email }}
  <a *ngIf="auth.isAdmin" class="sub add" (click)="add()">Add User</a>
  <a
    *ngIf="!auth.isAdmin"
    class="sub add"
    (click)="changePasswordPopup(auth.user.uid, auth.user.email)"
    >Change password</a
  >
</h3>
<div *ngIf="adding" class="content">
  <dt><label>Email*</label></dt>
  <dd>
    <input
      [ngClass]="{ error: error }"
      placeholder="Email"
      [(ngModel)]="email"
    />
  </dd>
  <dt><label>Password*</label></dt>
  <dd>
    <input
      [ngClass]="{ error: error }"
      placeholder="Password"
      [(ngModel)]="password"
    />
  </dd>
  <dt><label>Your password for verification*:</label></dt>
  <dd>
    <input
      [ngClass]="{ error: error }"
      placeholder="Input your password"
      [(ngModel)]="currentPassword"
    />
  </dd>
  <dt><label>Is Admin:</label></dt>
  <dd><input id="radio" type="checkbox" (change)="setAdmin()" /></dd>
  <button (click)="addUser()">Add</button><br />
  <a class="sub" (click)="cancel()">Cancel</a>
</div>
<div *ngIf="changingPassword" class="content">
  <dt><label>Your current password for verification*:</label></dt>
  <dd>
    <input
      [ngClass]="{ error: error }"
      placeholder="Input your current password"
      [(ngModel)]="currentPassword"
      type="password"
    />
  </dd>
  <dt><label>Your new password*:</label></dt>
  <dd>
    <input
      [ngClass]="{ error: error }"
      placeholder="Input your new password"
      [(ngModel)]="newPassword"
      type="password"
    />
  </dd>
  <button (click)="changePassword()">Change Password</button><br />
  <a class="sub" (click)="cancelPasswordChange()">Cancel</a>
</div>
<div *ngIf="!delete" class="table">
  <!--Admin Table-->
  <table *ngIf="auth.isAdmin" class="userTable">
    <tr>
      <th>Email</th>
      <th>Admin status</th>
      <th>Is enabled</th>
    </tr>
    <tr *ngFor="let user of usersList">
      <td>
        <a class="sub changePass" (click)="sendPasswordResetEmail(user.email)"
          >Send password reset email</a
        >
        {{ user.email }}
        <span
          *ngIf="user.uid === auth.user.uid"
          style="color: darkgreen; font-size: 13px;"
          >(Current User)
          <a class="sub delete" (click)="deleting(user.key)"
            >Delete User</a
          ></span
        >
      </td>
      <td *ngIf="user.isAdmin">
        Yes
        <a
          class="sub edit"
          (click)="removeAdmin(user.email, user.uid, user.key)"
          >Change Status</a
        >
      </td>
      <td *ngIf="!user.isAdmin">
        No
        <a class="sub edit" (click)="makeAdmin(user.email, user.uid, user.key)"
          >Change Status</a
        >
      </td>
      <td *ngIf="user.isEnabled">
        Yes
        <a
          *ngIf="!user.isAdmin"
          class="sub edit"
          (click)="disableUser(user.email, user.uid, user.key)"
          >Change Status</a
        >
      </td>
      <td *ngIf="!user.isEnabled">
        No
        <a
          *ngIf="!user.isAdmin"
          class="sub edit"
          (click)="enableUser(user.email, user.uid, user.key)"
          >Change Status</a
        >
      </td>
    </tr>
  </table>

  <!--Non-Admin Table-->
  <table *ngIf="!auth.isAdmin" class="userTable">
    <tr>
      <th>Email</th>
    </tr>
    <tr>
      <td>
        {{ auth?.user?.email }}
        <span style="font-size: 13px;">
          <a class="sub delete" (click)="deleting(auth.user.uid)"
            >Delete Account</a
          >
        </span>
      </td>
    </tr>
  </table>
</div>
<div *ngIf="delete" class="content">
  <p class="alert">
    Are you sure you want to delete your account? This is irreversible.
  </p>
  <button (click)="deleteUser()">Yes</button>
  <button (click)="notDeleting()">No</button>
</div>
