export enum Location {
    NotSet,
    Lincoln,
    Kearney,
    Sandhills,
    Sarpy,
    Southeast
}

export const LocationCodeMapper = {
    [Location.NotSet]: '',
    [Location.Lincoln]: 'lincolnne',
    [Location.Kearney]: 'centralne',
    [Location.Sandhills]: 'sandhillsne',
    [Location.Sarpy]: 'sarpyne',
    [Location.Southeast]: 'southeastne'
}

export const LocationNameMapper = {
    [Location.NotSet]: '',
    [Location.Lincoln]: 'Lincoln NE',
    [Location.Kearney]: 'Central NE',
    [Location.Sandhills]: 'Sandhills NE',
    [Location.Sarpy]: 'Sarpy NE',
    [Location.Southeast]: 'Southeast NE'
}
