<h3>Edit Schedule</h3>
<dl>
  <p class="alert">{{ message }}</p>
  <dt>
    <Label>Cycle:</Label>
  </dt>
  <dd>
    <select [(ngModel)]="cycle">
      <option *ngFor="let cycle of cycleList" [value]="cycle">{{
        cycle
      }}</option>
    </select>
  </dd>

  <dt>
    <Label>Day:</Label>
  </dt>
  <dd>
    <select [(ngModel)]="day">
      <option *ngFor="let day of dayList" [value]="day">{{ day }}</option>
    </select>
  </dd>

  <dt>
    <Label *ngIf="!this.isSafari">Start Time (Ex 08:00 AM)</Label>
    <label *ngIf="this.isSafari">Start Time: Please enter start time in Military Time (Ex 08:00)</label>
  </dt>
  <dd>
    <input type="time" [(ngModel)]="startTime" />
  </dd>
  <dt>
    <Label *ngIf="!this.isSafari">End Time (Ex 05:00 PM)</Label>
    <label *ngIf="this.isSafari">End Time: Please enter end time in Military Time (Ex 17:00)</label>
  </dt>
  <dd>
    <input type="time" [(ngModel)]="endTime" />
  </dd>
</dl>
<span>
  <button (click)="back()" class="cancel">Cancel</button>
  <button (click)="updateAll()" class="submit-update">Update</button>
</span>
