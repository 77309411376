import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Subcategory } from '../categories/category';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { ObjectCache } from '../Models/objectCache.model';
import { Observable } from 'rxjs';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class SubcategoryService extends DataService<Subcategory> {
  constructor(private db: AngularFireDatabase, private ps: PreferencesService) {
    super(db, '', new ObjectCache<Subcategory>());
  }

  getSubcategoryById(
    categoryId: string,
    subcategoryId: string,
    lang?: string
  ): Observable<Subcategory> {
    return super.getObjectById(
      `entities/${this.ps.getLocationCode()}/Data/en/Categories/${categoryId}/Subcategories/${subcategoryId}`,
      lang
    );
  }

  getSubcategoriesByProgramId(
    programId: string,
    lang?: string
  ): Observable<Subcategory[]> {
    return super.getList(`entities/${this.ps.getLocationCode()}/Data/en/ProgramList/${programId}/SubcategoryIds`, lang);
  }

  getSubcategoriesByCategoryId(
    categoryId: string,
    lang?: string
  ): Observable<Subcategory[]> {
    return super.getList(`entities/${this.ps.getLocationCode()}/Data/en/Categories/${categoryId}/Subcategories`, lang);
  }

  getUpdatableSubcategories(
    categoryId: string,
    lang?: string
  ): AngularFireList<Subcategory> {
    return super.getAngularFireObjectsByPath(
      `entities/${this.ps.getLocationCode()}/Data/en/Categories/${categoryId}/Subcategories`,
      lang
    );
  }

  async getSingleSubcategory(
    categoryId: string,
    subcategoryId: string,
    lang?: string
  ): Promise<Subcategory> {
    if (!lang) {
      lang = 'en';
    }
    const result = await super.getRawDataFromPath(`entities/${this.ps.getLocationCode()}/Data/${lang}/Categories/${categoryId}/Subcategories/${subcategoryId}`);
    let results: Subcategory;
    results = result;
    return results;
  }

  updateSubcategory(categoryId: string,
    subcategoryId: string,
    subcategory: Subcategory,
    lang?: string): void {
      if(!lang){
        lang = 'en';
      }
      this.getUpdatableSubcategories(categoryId, lang).set(subcategoryId, subcategory)
    }

  async findSubcategory(
    categoryId: string,
    subcategoryId: string,
    lang?: string
  ): Promise<Subcategory> {
    return await super.findDocument(
      `entities/${this.ps.getLocationCode()}/Data/en/Categories/${categoryId}/Subcategories/${subcategoryId}`,
      lang
    );
  }  
}
