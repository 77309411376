import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { User } from '../admin/user';
import { Admin } from '../admin/admin';
import { ThemeService } from '../Services/theme.service';
import { UserService } from '../Services/user.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  users: User[];
  adminList: Admin[];
  delete: boolean = false;
  userToDelete: string;
  adminKey: string;
  appName: string;

  constructor(
    private auth: FirebaseAuthService,
    private afAuth: AngularFireAuth,
    private userAccessor: UserService,
    private themeAccessor: ThemeService
  ) {
    themeAccessor.getThemeElement('appName').subscribe((element) => {
      if (element) {
        this.appName = element;
      }
    });
  }

  deleting() {
    this.delete = true;
    this.userAccessor.findUsers();
  }

  notDeleting() {
    this.delete = false;
  }

  deleteUser() {
    this.adminList = this.userAccessor.adminList;
    this.users = this.userAccessor.userList;
    for (var i = 0; i < this.users.length; i++) {
      if (this.users[i]['uid'] === this.auth.user.uid) {
        this.userToDelete = this.users[i]['key'];
      }
    }
    for (var i = 0; i < this.adminList.length; i++) {
      if (this.adminList[i]['uid'] === this.auth.user.uid) {
        this.adminKey = this.adminList[i]['key'];
      }
    }
    this.auth.deleteUser(this.userToDelete, this.adminKey);
    this.delete = false;
  }

  ngOnInit() {
    this.auth.user = this.afAuth.auth.currentUser;
    this.userAccessor.checkAdmin();
  }
}
