<div id="header">
  <h3 id="header-text">Customize Theme</h3>
  <div id="header-button">
    <button matButton (click)="saveChanges()">Save Changes</button>
  </div>
</div>

<div class="table-container mat-elevation-z8">
  <div class="example-header">
    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="Search"
      />
    </mat-form-field>
  </div>

  <mat-table #table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="Name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let name">
        {{ getDisplayName(name) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Value">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Value</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input
          matInput
          *ngIf="isString(element['Value']) && !isImage(element)"
          type="text"
          [(ngModel)]="element['Value']"
        />
        <img id="image" *ngIf="isImage(element)" src="{{ element['Value'] }}" />
        <input
          matInput
          *ngIf="isHex(element['Value'])"
          type="color"
          [(ngModel)]="element['Value']"
        />
        <input
          matInput
          *ngIf="!isString(element['Value']) && !isHex(element['Value'])"
          type="int"
          [(ngModel)]="element['Value']"
        />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Buttons">
      <mat-header-cell *matHeaderCellDef>Buttons</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input
          class="ng-hide"
          *ngIf="isImage(element)"
          (change)="
            uploadFileToStorage($event.target.files[0], element['Name'])
          "
          type="file"
          id="fileUpload"
        />
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
