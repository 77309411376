<h3>PDF Generation</h3>
<p>
  Generate a PDF document with a list of Programs and Organizations that can be
  printed.<br />Please be aware that not all devices may be able to display PDF
  documents correctly.<br />Generation may take a moment.
</p>

<button (click)="genPDF(selector.value, bodySize.value)">
  Click Here to generate PDF
</button>
Text Size: <input #bodySize type="number" value="16" size="3" maxlength="3" />px
  Paper Type:
<select #selector>
  <option value="letter">Letter (8.5x11)</option
  ><option value="a4">A4 (8.27x11.69)</option
  ><option value="legal">Legal (8.5x14)</option></select
>

<h3>Spreadsheet Generation</h3>
<p>
  Generate a CSV file of all organizations.
</p>

<button (click)="exportOrganizations()">Export Organizations</button>

<p>
  Generate a CSV file of all programs.
</p>

<span>Please select a language: </span>
<select [(ngModel)]="selectedLanguage">
  <option value="ar">Arabic</option>
  <option value="en">English</option>
  <option value="es">Spanish</option>
  <option value="so">Somali</option>
  <option value="vi">Vietnamese</option>
</select>
<button (click)="exportPrograms()">Export Programs</button>

<span class="download-text" *ngIf="isDownloading">Please wait while the csv file is downloading...</span>