import { Component, OnInit } from '@angular/core';
import { Organization } from '../organizations/organization';
import { Program } from '../Models/program.model';
import { ScheduledEvent } from '../scheduled/scheduledEvent';
import { Router } from '@angular/router';
import { ProgramService } from '../Services/program.service';
import { OrganizationService } from '../Services/organization.service';
import { ScheduleService } from '../Services/schedule.service';

@Component({
  selector: 'missing-data',
  templateUrl: './missingData.component.html',
  styleUrls: ['./missingData.component.css'],
})
export class MissingDataComponent implements OnInit {
  organizations: Organization[];
  programs: Program[];
  events: ScheduledEvent[];
  missingOrgs: Organization[] = [];
  missingPrograms: Program[] = [];
  missingEvents: ScheduledEvent[] = [];
  selected: string;
  orgSelected: boolean = false;
  programSelected: boolean = false;
  eventSelected: boolean = false;

  constructor(
    private router: Router,
    private programAccessor: ProgramService,
    private organizationAccessor: OrganizationService,
    private scheduleAccessor: ScheduleService
  ) {}

  changed() {
    this.missingOrgs = [];
    this.missingPrograms = [];
    this.missingEvents = [];
    if (this.selected === 'organizations') {
      this.orgSelected = true;
      this.programSelected = false;
      this.eventSelected = false;
    } else if (this.selected === 'programs') {
      this.orgSelected = false;
      this.programSelected = true;
      this.eventSelected = false;
    } else if (this.selected === 'events') {
      this.orgSelected = false;
      this.programSelected = false;
      this.eventSelected = true;
    }
  }

  filterList(filter: string) {
    this.missingOrgs = [];
    this.missingPrograms = [];
    this.missingEvents = [];
    if (this.orgSelected) {
      for (var i = 0; i < this.organizations.length; i++) {
        if (this.organizations[i][filter] === '') {
          this.missingOrgs.push(this.organizations[i]);
        }
      }
    }
    if (this.programSelected) {
      for (var i = 0; i < this.programs.length; i++) {
        if (this.programs[i][filter] === '') {
          this.missingPrograms.push(this.programs[i]);
        }
      }
    }
    if (this.eventSelected) {
      for (var i = 0; i < this.events.length; i++) {
        if (this.events[i][filter] === '') {
          this.missingEvents.push(this.events[i]);
        }
      }
    }
  }

  goto(catId: string, key: string) {
    this.programAccessor.getProgramById(key);
    this.router.navigate(['/home/program/' + key]);
  }

  eventGoTo(key: string) {
    var catId: string;
    for (var i = 0; i < this.programs.length; i++) {
      if (this.programs[i]['key'] === key) {
        catId = this.programs[i]['CategoryId'];
      }
    }
    this.programAccessor.getProgramById(key);
    this.router.navigate(['/home/program/' + key]);
  }

  orgGoTo(key: string) {
    this.router.navigate(['/home/org-detail/' + key]);
  }

  ngOnInit() {
    this.organizations = this.organizationAccessor.getCachedOrganizations();
    this.programs = this.programAccessor.getCachedPrograms();
    this.events = this.scheduleAccessor.getAllEvents();

    this.organizationAccessor
      .getUpdatableOrganizations()
      .snapshotChanges()
      .subscribe((snapshots) => {
        this.organizations = [];
        snapshots.forEach((snapshot) => {
          const organization: Organization = snapshot.payload.val();
          organization.key = snapshot.key;
          this.organizations.push(organization);
        });
      });

    this.programAccessor
      .getUpdatablePrograms()
      .snapshotChanges()
      .subscribe((snapshots) => {
        this.programs = [];
        snapshots.forEach((snapshot) => {
          const program: Program = snapshot.payload.val();
          program.key = snapshot.key;
          this.programs.push(program);
        });
      });

    this.scheduleAccessor
      .getUpdatableEvents()
      .snapshotChanges()
      .subscribe((snapshots) => {
        this.events = [];
        snapshots.forEach((snapshot) => {
          const event: ScheduledEvent = snapshot.payload.val();
          event.key = snapshot.key;
          this.events.push(event);
        });
      });
  }
}
