import { Component } from '@angular/core';
import { FirebaseAuthService } from '../firebase/firebaseAuth.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  constructor(public auth: FirebaseAuthService) {}

  loginUser(email: string, password: string) {
    this.auth.login(email, password);
  }
}
