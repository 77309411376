import { Timestamp } from '../programs/timestamp';

export class Organization {
  Address?: string;
  Call?: string;
  Email?: string;
  Image?: string;
  Name?: string;
  Website?: string;
  Timestamp?: Timestamp;
  key?: string;
  userUpdated?: boolean;
}
